"use client";
import env from "@/env.mjs";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";

type Props = {
  children: React.ReactNode;
};

export const client = algoliasearch(
  env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN,
);

const AlgoliaSearchProvider = ({ children }: Props) => {
  return (
    <InstantSearch indexName="storyblok_journal" searchClient={client}>
      {children}
    </InstantSearch>
  );
};

export default AlgoliaSearchProvider;
