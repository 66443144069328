"use client";
import useLocation from "@/lib/hooks/useLocation";
import { useCart } from "@/lib/hooks/useSelection";
import {
  convertCartToGTagItem,
  eventViewCart,
} from "@/lib/utils/Analytics/Functions";
import { OwnersClubStoryblok } from "@/types/storyblok-blok-types";
import { useEffect, useRef } from "react";
import CartContent from "./CartContent";

interface ICart {
  ownersClubBlok?: OwnersClubStoryblok;
}
const Cart = ({ ownersClubBlok }: ICart) => {
  const { items, modal, cart } = useCart();
  const { currency } = useLocation();
  const eventFired = useRef(false);

  useEffect(() => {
    if (eventViewCart && modal.isOpen)
      if (!eventFired.current) {
        eventViewCart({
          currency: currency || "",
          items: items.map((item) => convertCartToGTagItem(item)),
          value: cart.summary?.prices.totalAsNumber,
        });
        eventFired.current = true;
      }
  }, [modal.isOpen, items, currency, cart.summary?.prices?.totalAsNumber]);
  useEffect(() => {
    if (!modal.isOpen) {
      eventFired.current = false;
    }
  }, [modal.isOpen]);

  return <CartContent ownersClubBlok={ownersClubBlok} />;
};

export default Cart;
